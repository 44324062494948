<script>
  export let travelContentElement
</script>

<div class="hidden">
  <div bind:this={travelContentElement}>
    <h2>travel:$ 32 countries</h2>
    <ul>
      <li>England</li>
      <li>Ireland</li>
      <li>Denmark</li>
      <li>Sweden</li>
      <li>Norway</li>
      <li>France</li>
      <li>Belgium</li>
      <li>Netherlands</li>
      <li>Germany</li>
      <li>Czechia</li>
      <li>Croatia</li>
      <li>Bosnia & Herzegovina</li>
      <li>Serbia</li>
      <li>Hungary</li>
      <li>Slovakia</li>
      <li>Iceland</li>
      <li>Russia</li>
      <li>Kosovo</li>
      <li>North Macedonia</li>
      <li>Albania</li>
      <li>Montenegro</li>
      <li>Romania</li>
      <li>Slovenia</li>
      <li>Italy</li>
      <li>Estonia</li>
      <li>Latvia</li>
      <li>Lithuania</li>
      <li>Poland</li>
      <li>Bulgaria</li>
      <li>Spain</li>
      <li>Malta</li>
    </ul>
  </div>
</div>
