<script>
  export let aboutContentElement
</script>

<div class="hidden">
  <div bind:this={aboutContentElement}>
    <h2>about-me:$</h2>
    <p>My name is Brad Mehder. I travel, code, and learn.</p>
    <p>I'm a minimilist.</p>
    <p>
      I'm facinated with functional programming and love using Svelte and SvelteKit to make websites and web applications.
    </p>
  </div>
</div>
